<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.push('/web/workersList')">职工列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学习记录</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <h3 style="padding-bottom:20px;">课程基本信息</h3>
            <div>
              <el-form :inline="true" ref="ruleForm" label-width="10rem">
                <el-form-item label="课程名称：" class="form-inline">
                  <p>{{ruleForm.courseName}}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.trainTypeNamePath" label="培训类型：" class="form-inline">
                  <p>{{ruleForm.trainTypeNamePath || ''}}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.industryNamePath" label="行业类型：" class="form-inline">
                  <p>{{ruleForm.industryNamePath || ''}}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.postName" label="岗位类型：" class="form-inline">
                  <p>{{ruleForm.postName || ''}}</p>
                </el-form-item>
                <el-form-item
                  v-show="ruleForm.occupationNamePath"
                  label="职业/工种："
                  class="form-inline"
                >
                  <p>{{ruleForm.occupationNamePath || ''}}</p>
                </el-form-item>
                <el-form-item v-show="ruleForm.trainLevelName" label="培训等级：" class="form-inline">
                  <p>{{ruleForm.trainLevelName || ''}}</p>
                </el-form-item>
              </el-form>
              <el-form ref="ruleForm" label-width="10rem">
                <el-form-item label="课程详情：" prop="context" class="form-item">
                  <div
                    v-html="ruleForm.context"
                    ref="editor"
                    style="width:100%;position: relative;z-index: 2;"
                  />
                </el-form-item>
                <el-form-item label="课程图片：" prop="thumbnail">
                  <div class="img-el-upload" style="width:240px">
                    <el-image
                      :src="ruleForm.thumbnail || require('@/assets/develop.png')"
                      fit="contain"
                    ></el-image>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <h3 style="padding-bottom:20px;">课程章节</h3>
            <div style="display: flex studentNodes">
              <div style="flex:1">
                <section class="chapterl-trees">
                  <el-tree :data="data" node-key="id" default-expand-all v-if="data">
                    <div class="custom-tree-node" slot-scope="{ node, data }">
                      <p v-if="data.level === 1">
                        <span class="ellipsis" style="margin-right:30px;">{{ node.label }}</span>
                      </p>
                      <template v-else-if="data.level === 2">
                        <span class="ellipsis" style="margin-right:38px;">{{ data.label }}</span>
                        <span v-if="data.completeState=='1'">已学完</span>
                        <span
                          v-else
                        >{{(data.oldTime && data.oldTime!= 0)? '已学' + ((data.oldTime/data.kpointDuration)*100).toFixed(2) + '%':'未学习'}}</span>
                        <span class="trees-btns" :canDarg="data.list.length">
                          <template v-if="data.list.length">
                            <span
                              class="draggable-item handle"
                              v-for="element in data.list"
                              :key="element.name"
                            ></span>
                          </template>
                        </span>
                      </template>
                      <template v-else>
                        <p class="flexac">
                          <i style="margin-right:5px;" class="el-icon-tickets"></i>
                          <span class="ellipsis" style="margin-right:0px;">{{ node.label }}</span>
                          <span>{{data.paperIsComplete? '已通过': '未通过'}}</span>
                        </p>
                      </template>
                    </div>
                  </el-tree>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NotesDetail",
  data() {
    return {
      detailResource: {},
      courseId: this.$route.query.courseId,
      userId: this.$route.query.userId,
      projectId: this.$route.query.projectId,
      projectCourseId: this.$route.query.projectCourseId,
      styleType: "",
      props: {
        value: "id",
        label: "label",
        emitPath: false
      },
      ruleForm: {},
      // tree
      draggable: true,
      treeLable: "",
      data: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      styleStu: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getDetail();
    },
    getDetail() {
      //this.courseId
      this.$post("/biz/projectCourseOutline/getUserLearnRecordCourseDetails", {
        // courseId: 161,
        // projectCourseId: 25,
        // projectId: 18,
        // userId: 8
        courseId: this.courseId,
        projectCourseId: this.projectCourseId,
        projectId: this.projectId,
        userId: this.userId
      }).then(result => {
        if (result.status === "0") {
          this.creatData(result.data);
        }
      });
    },
    creatData(data = {}) {
      const ret = {
        courseName: data.courseName,
        context: data.context,
        thumbnailId: data.logo,
        thumbnail: data.logoURL,
        trainTypeNamePath: data.trainTypeNamePath,
        postName: data.postName,
        industryNamePath: data.industryNamePath,
        occupationNamePath: data.occupationNamePath,
        trainLevelName: data.trainLevelName
      };
      this.ruleForm = ret;
      let list = [];
      if (data.nodes && data.nodes.length) {
        data.nodes.forEach(element => {
          let nodeList = [];
          if (element.child) {
            // 目录样式  1
            this.styleStu = true;
            //
            (element.child || []).forEach(obj => {
              // children 表示试卷所在级
              let children = [];
              if (obj.paperId) {
                children = [
                  {
                    paperId: obj.paperId,
                    label: obj.paperName,
                    paperIsComplete: obj.paperIsComplete,
                    level: 3
                  }
                ];
              }
              // listArr 表示课件放在二级里的list里
              let listArr = [];
              if (obj.kpointVideoId) {
                listArr.push({
                  kpointVideoId: obj.kpointVideoId,
                  kpointName: obj.kpointName
                });
              }
              // 第二级
              nodeList.push({
                label: obj.catalogName,
                level: 2,
                completeState: obj.completeState,
                oldTime: obj.oldTime,
                kpointDuration: obj.kpointDuration,
                list: listArr,
                children: children
              });
            });
            list.push({
              label: element.catalogName,
              children: nodeList,
              level: 1
            });
          } else {
            // 目录样式  2
            this.styleStu = false;
            let children = [];
            if (element.paperId) {
              children = [
                {
                  paperId: element.paperId,
                  label: element.paperName,
                  level: 3
                }
              ];
            }
            let listArr = [];
            if (element.kpointVideoId) {
              listArr.push({
                kpointVideoId: element.kpointVideoId,
                kpointName: element.kpointName
              });
            }
            list.push({
              label: element.catalogName,
              children: children,
              level: 2,
              list: listArr,
              completeState: element.completeState,
              oldTime: element.oldTime,
              kpointDuration: element.kpointDuration,
              paperIsComplete: element.paperIsComplete
            });
          }
        });
      }

      this.data = list;
    }
    // section2Item(obj) {
    //   let children = [];
    //   if (obj.paperId) {
    //     children = [
    //       {
    //         paperId: obj.paperId,
    //         label: obj.paperName,
    //         level: 3
    //       }
    //     ];
    //   }
    //   let listArr = [];
    //   if (obj.kpointVideoId) {
    //     obj.list = listArr;
    //     listArr.push({
    //       kpointVideoId: obj.kpointVideoId,
    //       kpointName: obj.kpointName,
    //       kpointDuration: obj.kpointDuration,
    //       kpointId: obj.kpointId,
    //       kpointLessonNum: obj.kpointLessonNum,
    //       kpointLogo: obj.kpointLogo,
    //       kpointType: obj.kpointType,
    //       qualificationId: obj.qualificationId,
    //       kpointDeleted: obj.kpointDeleted, // 课件是否删除了
    //       progress: 100
    //     });
    //   }
    //   return {
    //     label: obj.catalogName,
    //     id: obj.courseKpointId,
    //     level: 2,
    //     list: listArr,
    //     children: children
    //   };
    // }
  }
};
</script>
<style lang="less">
.formCom {
  .el-form-item {
    margin-bottom: 5px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
  }
  .chapterl-trees {
    padding: 15px 15px 15px 0;
    .el-tree-node__content {
      height: 36px;
    }
    .draggable-item {
      margin-left: 5px;
      em {
        margin-right: 5px;
      }
    }
    .trees-btns {
      margin-left: 15px;
      & > .el-button,
      .handle > .el-button {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      i {
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .cl-img-box {
    .el-icon-receiving {
      font-size: 40px;
      color: #999;
      margin-right: 15px;
    }
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      span {
        min-width: 4em;
      }
    }
  }
}
.img-el-upload {
  padding: 20px 0;
  min-height: 120px;
}
.butbox {
  display: flex;
  justify-content: center;
}
</style>
<style lang="less">
.studentNodes {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 50rem;
    font-size: 14px;
  }
}
</style>
